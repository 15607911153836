import React from "react";
import { AcademicCapIcon, HomeIcon, ChatIcon } from "@heroicons/react/solid";
import SyndicationIcon from "../components/widgets/icons/SyndicationIcon";
import Logout from "../pages/Logout";
import Loader from "../components/widgets/Loader";
import SettingsIcon from "../components/widgets/icons/SettingsIcon";
import TrainingIcon from "../components/widgets/icons/TrainingIcon";
import TrainingRegistrationForm from "../features/trainingPortal/TrainingRegistrationForm";
import TrainingHome from "../features/trainingPortal/TrainingHome";
import RegisteredListingIcon from "../components/widgets/icons/RegisteredListingIcon";
import AppSettingsIcon from "../components/widgets/icons/AppSettingsIcon";
import LandingPage from "../features/landingPage/LandingPage";
import AppSettings from "../features/appSettings/AppSettings";
import RegisteredListings from "../features/registeredListing/RegisteredListings";
import RegisteredListing from "../features/registeredListing/RegisteredListing";
import Profile from "../pages/Profile";
import Home from "../pages/Home";
import GeneralSettings from "../features/generalSettings/GeneralSettings";
import NewsArticle from "../features/news/Article";
import NewsArticles from "../features/news/Articles";
import Notifications from "../features/notification/Notifications";
import SystemNotifications from "../features/systemNotification/SystemNotifications";
import AorMessages from "../features/systemNotification/AorMessages";
import AorMessage from "../features/systemNotification/AorMessage";
import PasswordChange from "../features/user/PasswordChange";
import PublicDashboard from "../pages/public/PublicDashboard";
import LoginPage from "../pages/public/Login";
import PasswordReset from "../pages/public/PasswordReset";
import LoggedOut from "../pages/public/LoggedOut";
import { DashboardLoader } from "../components/widgets/SkeletonScreens";
import ForgotPassword from "../pages/public/ForgotPassword";
import MemberInsight from "../features/systemNotification/MemberInsights/MemberInsight";

export type routeObject = {
	id?: string;
	title?: string;
	path?: string;
	exact?: boolean;
	component?: React.FC | null;
	icon?: React.FunctionComponent<any>;
	// icon: ElementType,
	target?: "_blank" | null;
	menu?: "user" | "main" | "none" | "quicklink" | "static";
	color?: string;
	viewOrder?: number | 0;
};

// Authorized routes
export const authRoutes: routeObject[] = [
	{
		path: "/",
		exact: true,
		component: Home,
		icon: HomeIcon,
	},
	{
		path: "/settings",
		exact: true,
		component: GeneralSettings,
		icon: SettingsIcon,
	},
	{
		path: "/settings/:urlTab?",
		exact: true,
		component: GeneralSettings,
		icon: SettingsIcon,
	},
	{
		path: "/product_settings",
		exact: true,
		component: AppSettings,
		icon: AppSettingsIcon,
	},
	{
		path: "/registered",
		exact: true,
		component: RegisteredListings,
		icon: RegisteredListingIcon,
	},
	{
		path: "/registered/:id",
		exact: true,
		component: RegisteredListing,
		icon: RegisteredListingIcon,
	},
	{
		path: "/training",
		exact: true,
		component: TrainingHome,
		icon: TrainingIcon,
	},
	{
		path: "/training/:urlTab?",
		exact: true,
		component: TrainingHome,
		icon: TrainingIcon,
	},
	{
		path: "/training/registration/:eventId",
		exact: true,
		component: TrainingRegistrationForm,
		icon: TrainingIcon,
	},
	{
		path: "https://choice.crmls.org/synd",
		exact: true,
		component: null,
		icon: SyndicationIcon,
	},
	{
		path: "/aor-messages",
		exact: true,
		component: AorMessages,
		icon: ChatIcon,
	},
	{
		path: "/aor-messages/:messageId",
		exact: true,
		component: AorMessage,
		icon: ChatIcon,
	},
	{
		path: "/member-insight/:insightId",
		exact: true,
		component: MemberInsight,
		icon: ChatIcon,
	},
	{
		path: "/profile",
		exact: true,
		component: Profile,
		icon: SettingsIcon,
	},
	{
		path: "/articles/",
		exact: true,
		component: NewsArticles,
		icon: AcademicCapIcon,
	},
	{
		path: "/article/:articleId",
		exact: true,
		component: NewsArticle,
		icon: AcademicCapIcon,
	},
	{
		path: "/notifications",
		exact: true,
		component: Notifications,
		icon: AcademicCapIcon,
	},
	{
		path: "/system-notifications",
		exact: true,
		component: SystemNotifications,
		icon: AcademicCapIcon,
	},
	{
		path: "/password-change",
		exact: true,
		component: PasswordChange,
		menu: "static",
	},
];

// Public routes
export const publicRoutes: routeObject[] = [
	{
		title: "Home",
		path: "/",
		exact: true,
		component: PublicDashboard,
		icon: HomeIcon,
		menu: "main",
		viewOrder: 1,
	},
	{
		path: "/login/:association?",
		exact: true,
		component: LoginPage,
		menu: "static",
	},
	{
		path: "/forgot-password",
		exact: true,
		component: ForgotPassword,
		menu: "static",
	},
	{
		path: "/logout",
		exact: true,
		component: Logout,
		icon: SettingsIcon,
	},
	{
		path: "/logged-out",
		exact: true,
		component: LoggedOut,
		icon: SettingsIcon,
		menu: "static",
	},
	{
		path: "/password-reset/:resetToken?",
		exact: true,
		component: PasswordReset,
		menu: "static",
	},
	{
		path: "/callback",
		exact: true,
		component: DashboardLoader,
		menu: "static",
	},
	// Quick links
	{
		title: "Brokers",
		path: "https://go.crmls.org/brokers/",
		color: "bg-yellow-500",
		target: "_blank",
		menu: "quicklink",
		viewOrder: 1,
	},
	{
		title: "Compliance",
		path: "https://go.crmls.org/compliance/",
		color: "bg-red-500",
		target: "_blank",
		menu: "quicklink",
		viewOrder: 2,
	},
	{
		title: "Education",
		path: "https://go.crmls.org/education/",
		color: "bg-purple-500",
		target: "_blank",
		menu: "quicklink",
		viewOrder: 3,
	},
	{
		title: "Knowledgebase",
		path: "https://kb.crmls.org/",
		color: "bg-blue-500",
		target: "_blank",
		menu: "quicklink",
		viewOrder: 4,
	},
	{
		title: "Marketplace",
		path: "https://go.crmls.org/marketplace/",
		color: "bg-green-500",
		target: "_blank",
		menu: "quicklink",
		viewOrder: 5,
	},
	{
		title: "Support",
		path: "https://go.crmls.org/support/",
		color: "bg-pink-500",
		target: "_blank",
		menu: "quicklink",
		viewOrder: 6,
	},
];

// Filter out any public routes where paths don't exist in authorized routes - public routes should be accessible after logging in
const tempPublicRoutes = publicRoutes.filter((publicRoute) => publicRoute.menu !== "quicklink" && !authRoutes.find((authRoute) => authRoute.path === publicRoute.path));
export const tempRoutes: routeObject[] = [...tempPublicRoutes, ...authRoutes];
