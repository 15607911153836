import "@syncfusion/ej2-react-buttons/styles/button/material.css";
import "@syncfusion/ej2-react-inputs/styles/input/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-buttons/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import "@syncfusion/ej2-react-inputs/styles/material.css";
import "@syncfusion/ej2-react-navigations/styles/material.css";
import "@syncfusion/ej2-react-splitbuttons/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { useCallback, useEffect, useRef, useState } from "react";
import { columnType } from "../../../components/widgets/sortableTable/SortableTable";
import compStyles from "./memberinsights.module.css";
import { getAllTickets, ticketTypeahead } from "../../../adapters";
import { ColumnDirective, ColumnsDirective, DataStateChangeEventArgs, GridComponent } from "@syncfusion/ej2-react-grids";
import { Inject, Page, Sort } from "@syncfusion/ej2-react-grids";
import ReactSlidingPane from "react-sliding-pane";
import dayjs from "dayjs";
import { getValue } from "@syncfusion/ej2-base";
import MemberInsight from "./MemberInsight";
import Loader from "../../../components/widgets/Loader";
import { BeatLoader } from "react-spinners";
import { debounce } from "lodash";

export const TicketColumns: columnType[] = [
	{
		field: "title",
		label: "Title",
		sortable: true,
		justify: "left",
		hideMobile: false,
	},
	{
		field: "createdOn",
		label: "Created",
		sortable: true,
		formatAs: "datetime",
		justify: "left",
		hideMobile: true,
	},
];

const placeholder = "Search by title or body text";
interface IWorkItemQ {
	data: { results: { title: string; createdOn: string }; totalResults: string };
}

const MemberInsights = () => {
	const styles = { ...compStyles };
	const [showSidePanel, setShowSidePanel] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [workItemId, setWorkItemId] = useState("");
	const [activePage, setActivePage] = useState(0);
	const [data, setData] = useState<{ result: IWorkItemQ["data"]["results"]; count: string }>();
	const [sortParams, setSortParams] = useState<any[]>([]);
	const gridControl = useRef<any>("");

	useEffect(() => {
		fetchData();
	}, [activePage, sortParams]);

	const fetchData = () => {
		getAllTickets({
			activePage: activePage,
			pageSize: 10,
			orderBy: sortParams,
		}).then((response: IWorkItemQ) => {
			if (gridControl.current) {
				gridControl.current.hideSpinner();
			}
			setData({
				result: response.data.results,
				count: response.data.totalResults,
			});
			setFirstLoad(false);
		});
	};

	const dateFormatter = (field: string, data: any) => {
		console.log();
		return dayjs(data.createdOn).format("MM/DD/YYYY");
	};

	const handleStateChange = (state: DataStateChangeEventArgs) => {
		if (state.action) {
			if (state.action.requestType === "sorting") {
				if ("sorted" in state) {
					let sortOrder: any[] = [];
					state.sorted?.forEach((sort) => {
						sortOrder.push({
							field: sort.name,
							direction: sort.direction === "ascending" ? "Asc" : "Desc",
						});
					});
					setSortParams(sortOrder);
				} else {
					setSortParams([]);
				}
			} else if (state.action.requestType === "paging") {
				if ((state.action as any).currentPage - 1 !== activePage) {
					setActivePage((state.action as any).currentPage - 1);
				}
			}
		} else {
			state.skip = 1;
		}
	};

	const handleSearch = (searchTerm: string) => {
		debounceSearch.cancel();
		ticketTypeahead(searchTerm).then((response) => {
			setActivePage(1);
			setData({
				result: response.data.results,
				count: response.data.totalResults,
			});
			setIsLoading(false);
		});
	};
	const debounceSearch = debounce(handleSearch, 500);
	const debouncedHandleSearch = useCallback(debounceSearch, []);

	return firstLoad ? (
		<Loader></Loader>
	) : (
		<>
			<div className={`${styles.button_container} grid lg:grid-cols-5 gap-0 mb-8 sm:grid-cols-1 md:grid-cols-1`}>
				<div>
					<a href="mailto:associationsupport@crmls.org">
						<button type="button" className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:order-1 sm:ml-3">
							Create A New Support Ticket
						</button>
					</a>
					<div style={{ display: "block" }}>
						<span className={styles.new_note}>* Please have your email client open.</span>
					</div>
				</div>

				<div>
					<a target="_blank" href="https://go.crmls.org/support/">
						<button type="button" className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:order-1 sm:ml-3">
							Message support now
						</button>
					</a>
				</div>
				<div className="col-span-3 hide-menu insight-search">
					<div style={{ position: "relative" }}>
						{isLoading && (
							<span className="insight-search-input-loader">
								<BeatLoader size={5} />
							</span>
						)}

						<input
							type="text"
							className="insight-search-input"
							placeholder={placeholder}
							onChange={(e) => {
								setIsLoading(true);

								debouncedHandleSearch(e.target.value);
							}}
						></input>
					</div>
				</div>
			</div>
			<div className="syncfusion-grid" style={{ display: "block", clear: "both" }}>
				<GridComponent
					rowSelected={(e) => {
						setWorkItemId(e.data.id);
						setShowSidePanel(true);
					}}
					ref={gridControl}
					dataStateChange={handleStateChange}
					dataSource={data}
					allowPaging={true}
					pageSettings={{ pageCount: 10, pageSize: 10 }}
					allowSorting={true}
				>
					<ColumnsDirective>
						<ColumnDirective field="title" headerText="Title" isPrimaryKey={true} />
						<ColumnDirective field="createdOn" headerText="Created On" valueAccessor={dateFormatter} width="140" />
					</ColumnsDirective>
					<Inject services={[Page, Sort]} />
				</GridComponent>
			</div>

			<ReactSlidingPane
				isOpen={showSidePanel}
				width={"85%"}
				title={"Insight Detail"}
				onRequestClose={() => {
					setShowSidePanel(false);
				}}
			>
				<MemberInsight id={workItemId} />
			</ReactSlidingPane>
		</>
	);
};

export default MemberInsights;
